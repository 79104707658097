import React from 'react'

// styled
import { Container, Slider1, Slider2, Slider3 } from './style'

type ImagesCarrosselProps = {
  index: number;
}

const ImagesCarrossel = ({ index }: ImagesCarrosselProps) => {
  return (
    <Container className='mb-lg-0'>
      <Slider1 className={`${index >= 1 && 'active'} ${index > 1 && 'unactive'}`} />
      <Slider2 className={`${index >= 2 && 'active'} ${index > 2 && 'unactive'}`} />
      <Slider3 className={`${index >= 3 && 'active'} ${index > 3 && 'unactive'}`} />
    </Container>
  )
}

export default ImagesCarrossel
