export default [
  {
    title: 'Benefícios de ser Inter One',
    description: '<p class="text-grayscale--500">O Inter One tem tudo para simplificar ainda mais o seu dia a dia. Confira os benefícios personalizados que você pode ter sem nenhum custo adicional:</p>',
    bgImage: require('../images/inter-one-benefit-1.png'),
    bgColor: '#E5D4C1',
  },
  {
    title: 'Cartão Platinum',
    description: '<p class="text-grayscale--500">É internacional, sem anuidade e garante mais pontos no Loop, o programa de benefícios do Inter.</p><p class="text-grayscale--500">Os pontos podem ser trocados por viagens, cashback extra, desconto na fatura e até em investimentos.</p>',
    bgImage: require('../images/inter-one-benefit-2.png'),
    bgColor: '#F8F9F1',
  },
  {
    title: 'Advisor Inter One',
    description: '<p class="text-grayscale--500">Um Super App 100% digital com relacionamento humanizado.</p><p class="text-grayscale--500">O advisor propõe soluções que fazem sentido com o seu momento de vida.</p><p class="text-grayscale--500">De compras no Inter Shop a contratação de produtos de crédito, o advisor e sua equipe são parceiros que cuidam de tudo pra você.</p>',
    bgImage: require('../images/inter-one-benefit-3.png'),
    bgColor: '#D7D5D8',
  },
  {
    title: 'Especialista em investimentos',
    description: '<p class="text-grayscale--500">Um profissional especializado para te ajudar a criar ou rentabilizar sua carteira de investimentos na Inter Invest.</p><p class="text-grayscale--500">Receba as melhores recomendações para aumentar a rentabilidade e acompanhar de perto a sua vida financeira.</p>',
    bgImage: require('../images/inter-one-benefit-4.png'),
    bgColor: '#F1F6F9',
  },
  {
    title: 'Área exclusiva',
    description: '<p class="text-grayscale--500">Um novo espaço é liberado no Super App para você entrar em contato com advisor e conferir condições selecionadas especialmente para quem é cliente Inter One.</p>',
    bgImage: require('../images/inter-one-benefit-5.png'),
    bgColor: '#FFF6EC',
  },
]
