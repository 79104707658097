import React, { useRef, useEffect, useState } from 'react'
import Stories from 'stories-react'
import 'stories-react/dist/index.css'

import storiesData from '../../../assets/data/_stories'
import StorieComponent from './storie-component/_index'

// styles
import { Section } from './style'

import { BenefitsPorps } from '../_index'

const ReactStories = ({ setOpenModal, setDobra }: BenefitsPorps) => {
  const [ sectionVisible, setSectionVisible ] = useState<boolean>(false)

  const sectionRef = useRef<HTMLDivElement>(null)

 const Storie1 = () =>
   <StorieComponent setOpenModal={setOpenModal} setDobra={setDobra} title={storiesData[0].title} description={storiesData[0].description} bgImage={storiesData[0].bgImage} bgColor={storiesData[0].bgColor} />
 const Storie2 = () =>
   <StorieComponent setOpenModal={setOpenModal} setDobra={setDobra} title={storiesData[1].title} description={storiesData[1].description} bgImage={storiesData[1].bgImage} bgColor={storiesData[1].bgColor} />
 const Storie3 = () =>
   <StorieComponent setOpenModal={setOpenModal} setDobra={setDobra} title={storiesData[2].title} description={storiesData[2].description} bgImage={storiesData[2].bgImage} bgColor={storiesData[2].bgColor} />
 const Storie4 = () =>
   <StorieComponent setOpenModal={setOpenModal} setDobra={setDobra} title={storiesData[3].title} description={storiesData[3].description} bgImage={storiesData[3].bgImage} bgColor={storiesData[3].bgColor} />
 const Storie5 = () =>
   <StorieComponent setOpenModal={setOpenModal} setDobra={setDobra} title={storiesData[4].title} description={storiesData[4].description} bgImage={storiesData[4].bgImage} bgColor={storiesData[4].bgColor} btnColor='orange' />

  const stories = [
    {
      type: 'component',
      duration: 9000,
      component: Storie1,
    },
    {
      type: 'component',
      duration: 9000,
      component: Storie2,
    },
    {
      type: 'component',
      duration: 9000,
      component: Storie3,
    },
    {
      type: 'component',
      duration: 9000,
      component: Storie4,
    },
    {
      type: 'component',
      duration: 9000,
      component: Storie5,
    },
  ]

  useEffect(() => {
    const observer = new IntersectionObserver(([ entry ]) => {
      if (entry.isIntersecting) {
        setSectionVisible(true)
        observer.disconnect()
      }
    })

    observer.observe(sectionRef.current)
    return () => observer.disconnect()
  }, [])

  return (
    <Section ref={sectionRef}>
      {
        sectionVisible && (
          <Stories
            stories={stories}
            defaultInterval={1500}
            loop={true}
            height={620}
            isPaused
          />
        )
      }
    </Section>
  )
}

export default ReactStories
