export default [
  {
    active: false,
    title: 'Cartão Platinum',
    text: '<p class="fs-14 lh-16 fs-xl-16 lh-xl-16 text-white fw-400 mb-2">É internacional, sem anuidade e garante mais pontos no Loop, o programa de benefícios do Inter.</p><p class="fs-14 lh-16 fs-xl-16 lh-xl-16 text-white fw-400 mb-2">Os pontos podem ser trocados por viagens, cashback extra, desconto na fatura e até em investimentos.</p>',
    images: {
      md: require('../images/inter-one-benefit-card-1-md.png'),
      lg: require('../images/inter-one-benefit-card-1-lg.png'),
      xl: require('../images/inter-one-benefit-card-1-xl.png'),
    },
    backgroundColor: '#E6D4C1',
  },
  {
    active: false,
    title: '<span class="d-xl-block">Advisor</span> Inter One',
    text: '<p class="fs-14 lh-16 fs-xl-16 lh-xl-16 text-white fw-400 mb-2">Um Super App 100% digital com relacionamento humanizado.</p><p class="fs-14 lh-16 fs-xl-16 lh-xl-16 text-white fw-400 mb-2">O advisor propõe soluções que fazem sentido com o seu momento de vida.</p><p class="fs-14 lh-16 fs-xl-16 lh-xl-16 text-white fw-400 mb-2">De compras no Inter Shop a contratação de produtos de crédito, o advisor e sua equipe são parceiros que cuidam de tudo pra você.</p>',
    images: {
      md: require('../images/inter-one-benefit-card-2-md.png'),
      lg: require('../images/inter-one-benefit-card-2-lg.png'),
      xl: require('../images/inter-one-benefit-card-2-xl.png'),
    },
    backgroundColor: '#D7D5D8',
  },
  {
    active: false,
    title: '<span class="d-xl-block">Especialista em</span> investimentos',
    text: '<p class="fs-14 lh-16 fs-xl-16 lh-xl-16 text-white fw-400 mb-2">Um profissional especializado para te ajudar a criar ou rentabilizar sua carteira de investimentos na Inter Invest.</p> <p class="fs-14 lh-16 fs-xl-16 lh-xl-16 text-white fw-400 mb-2">Receba as melhores recomendações para aumentar a rentabilidade e acompanhar de perto a sua vida financeira.</p>',
    images: {
      md: require('../images/inter-one-benefit-card-3-md.png'),
      lg: require('../images/inter-one-benefit-card-3-lg.png'),
      xl: require('../images/inter-one-benefit-card-3-xl.png'),
    },
    backgroundColor: '#F1F6F9',
  },
  {
    active: false,
    title: '<span class="d-xl-block">Área</span> exclusiva',
    text: '<p class="fs-14 lh-16 fs-xl-16 lh-xl-16 text-white fw-400 mb-2">Um novo espaço liberado no Super App para você entrar em contato com o seu advisor e conferir condições selecionadas especialmente para quem é cliente Inter One.</p>',
    images: {
      md: require('../images/inter-one-benefit-card-4-md.png'),
      lg: require('../images/inter-one-benefit-card-4-lg.png'),
      xl: require('../images/inter-one-benefit-card-4-xl.png'),
    },
    backgroundColor: '#FFF6EC',
  },
]
