import styled from 'styled-components'
import { white, orange } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

type SectionProps = {
  bgColor: string;
  bgImage: string;
}

type ButtonContainer = {
  bgColor: string;
}

export const Section = styled.section`
  width: 100%;
  height: 620px;
  background-color: ${(props: SectionProps) => props.bgColor };
  background-image: url(${(props: SectionProps) => props.bgImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: calc(100% + 80px);

  .container, .row {
    height: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
export const Button = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background: ${orange.extra};
  color:  ${white};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  border: none;
  z-index: 10;
  margin-bottom: 50px;
  filter: drop-shadow(0px -4px 15px rgba(0, 0, 0, 0.25));

  &:focus {
    outline: none;
  }

  @media(min-width: ${breakpoints.md}) {
    max-width: 312px;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${white};
  width: calc(100% + 48px);
  margin-left: -24px;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.25);
  background-color: ${(props: ButtonContainer) => props.bgColor };
`
