import React, { useState, useLayoutEffect } from 'react'
import { Parallax } from 'react-scroll-parallax'

import useWidth from 'src/hooks/window/useWidth'

import Stories from './react-stories/_index'
import Cards from './cards/_index'

const WIDTH_MD = 768

export type BenefitsPorps = {
  setOpenModal: Function;
  setDobra: Function;
}

export const Benefits = ({ setOpenModal, setDobra }: BenefitsPorps) => {
  const [ isMobile, setIsMobile ] = useState(true)

  const width = useWidth(300)

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <Parallax
      speed={-30}
      translateY={[ 0, 0, 'easeIn' ]}
      scale={[ 1, 1, 'easeInOutBack' ]}
      id='benefits'
      className='stories'
    >
      {
        isMobile ? <Stories setDobra={setDobra} setOpenModal={setOpenModal} /> : <Cards setDobra={setDobra} setOpenModal={setOpenModal} />
      }
    </Parallax>
  )
}

export default Benefits
