import React, { useState } from 'react'
import CartImage from 'src/components/Card-Image'
import cadsData from '../../../assets/data/_cards'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Row, Button } from './styles'

import { BenefitsPorps } from '../_index'

export type CardListProps = {
  active: boolean;
  title: string;
  text: string;
  images: {
    md: string;
    lg: string;
    xl: string;
  };
  backgroundColor: string;
}

const Cards = ({ setOpenModal, setDobra }: BenefitsPorps) => {
  const [ cardList, setCardList ] = useState(cadsData)
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = (position: number) => {
    const newList = cardList.map((item: CardListProps, index: number) => {
      if (index === position) {
        return {
          ...item,
          active: !item.active,
        }
      } else {
        return {
          ...item,
          active: false,
        }
      }
    })

    setCardList(newList)
  }

  return (
    <Section className='py-5'>
      <div className='container'>
        <h2 className='fs-32 lh-40 fs-lg-48 lh-lg-60 text-grayscale--500 fw-600 text-center'>Benefícios de ser Inter One</h2>
        <h3 className='fs-16 lh-20 text-grayscale--400 fw-400 text-center'>
          <span className='d-block'>Pra você que alcançou muito e sabe que merece mais. </span>
          Confira os benefícios personalizados que você pode ter sem nenhum custo adicional:
        </h3>

        <Row className='d-none d-lg-flex'>
          {
            cardList.map((item: CardListProps, index: number) => (
              <CartImage
                title={item.title}
                text={item.text}
                images={item.images}
                key={item.text}
                setActive={handleClick}
                position={index}
                active={item.active}
                backgroundColor={item.backgroundColor}
              />
            ))
          }
        </Row>

        <div className='row d-lg-none'>
          {
            cardList.map((item: CardListProps, index: number) => (
              <div className='col-md-6 col-lg-3' key={item.text}>
                <CartImage
                  title={item.title}
                  text={item.text}
                  images={item.images}
                  setActive={handleClick}
                  position={index}
                  active={item.active}
                  backgroundColor={item.backgroundColor}
                />
              </div>
            ))
          }
        </div>

        <div className='d-flex justify-content-center'>
          <Button
            onClick={() => {
              setOpenModal(true)
              setDobra('dobra_3')
              sendDatalayerEvent({
                section: 'dobra_03',
                element_action: 'click button',
                element_name: 'Quero ser Inter One',
                section_name: 'Benefícios de ser Inter One',
               })
            }}
            className='mb-5 mt-3'
          >
            Quero ser Inter One
          </Button>
        </div>
      </div>
    </Section>
  )
}
export default Cards
