import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { white, orange, grayscale } from 'src/styles/colors'

import bgSm from '../../assets/images/lp-one-knowMore-sm.png'
import bgMd from '../../assets/images/lp-one-knowMore-md.png'
import bgLg from '../../assets/images/lp-one-knowMore-lg.png'
import bgXl from '../../assets/images/lp-one-knowMore-xl.png'

export const Section = styled.section`
  background-color: #484848;
  background-image: url(${bgSm});
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 635px;

  @media(min-width: ${breakpoints.md}) {
    background-image: url(${bgMd});
    min-height: 479px;
  } 
  @media(min-width: ${breakpoints.lg}) {
    background-image: url(${bgLg});
    min-height: 543px;
  } 
  @media(min-width: ${breakpoints.xl}) {
    background-image: url(${bgXl});
  }

  .title {
    color: ${white};

    @media (min-width: ${breakpoints.md}) {
      color: ${orange.extra};
    }
  }

  .phone {
    color: ${orange.extra};
    font-weight: 600;

    @media (min-width: ${breakpoints.md}) {
      color: ${white};
      font-weight: 400;
    }
  }

  .line {
    height: 1px;
    width: 100%;
    background: ${grayscale[400]};

    @media (min-width: ${breakpoints.md}) {
      width: 1px;
      height: 142px;
      position: absolute;
      left: -24px;
    }
  }
`
