import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Button } from './style'

export type StorieComponentProps = {
  title: string;
  description: string;
  bgImage: string;
  bgColor: string;
  setOpenModal: Function;
  btnColor?: 'orange' | 'white';
  setDobra: Function;
}

const StorieComponent = ({ title, description, bgImage, bgColor, setOpenModal, setDobra }: StorieComponentProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section
      bgColor={bgColor}
      bgImage={bgImage}
      className='pt-5'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 content px-0'>
            <div>
              <h2 className='fs-28 lh-35 text-grayscale--500 fw-600'>{ title }</h2>
              <div
                className='fs-14 lh-17 fw-400'
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>

            <Button
              onClick={() => {
                setOpenModal(true)
                setDobra('dobra_3')
                sendDatalayerEvent({
                  section: 'dobra_03',
                  element_action: 'click button',
                  element_name: 'Quero ser Inter One',
                  section_name: title,
                 })
              }}
            >
              Quero ser Inter One
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default StorieComponent
