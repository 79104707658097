import React, { useState, useEffect } from 'react'
import { Parallax } from 'react-scroll-parallax'
import { widths } from 'src/styles/breakpoints'

import useScroll from 'src/hooks/window/useScroll'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import logoInterOne from '../../assets/images/logoInterOne.svg'
import logoInterOneDark from '../../assets/images/logoInterOneDark.svg'

import LinkTo from 'src/components/LinkTo/LinkTo'

import { Section, Button } from './style'

function isPosition (width: number) {
  if (width < widths.md) {
    return {
      first: -10,
      last: 90,
    }
  } else if (width >= widths.md && width < widths.xl) {
    return {
      first: -10,
      last: 120,
    }
  } else if (width >= widths.lg) {
    return {
      first: -5,
      last: 100,
    }
  } else {
    return {
      first: -20,
      last: 160,
    }
  }
}

const Hero = () => {
  const [ scrolled, setScrolled ] = useState(false)
  const scrollPositionY = useScroll(100)
  const [ sendDatalayerEvent ] = useDataLayer()

  const width = useWidth(300)

  useEffect(() => {
    if (scrollPositionY > 100) {
      setScrolled(true)
    } else if (scrollPositionY + 48 < 100) {
      setScrolled(false)
    }
  }, [ scrollPositionY ])

  return (
    <Parallax
      speed={-30}
      translateY={[ isPosition(width).first, isPosition(width).last, 'easeIn' ]}
      scale={[ 1, 1, 'easeInOutBack' ]}
    >
      <Section
        scrolled={scrolled}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6 col-lg-7 col-xl-6 offset-md-2 pl-xl-5'>
              <img src={scrolled ? logoInterOneDark : logoInterOne } className='mb-2' />
              <h1 className='fs-32 lh-40 fs-md-40 lh-md-50 fs-lg-48 lh-lg-60 fs-xl-48 lh-xl-60 fw-600 text'>
                Você na frente
              </h1>
              <p className='fs-14 lh-17 fs-md-16 lh-md-20 fw-400 text'>
                <span className='d-lg-block'>Benefícios personalizados e atendimento humanizado </span>
                que entende a sua importância.
              </p>
              <LinkTo to='#benefits'>
                <Button
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click scroll',
                      element_name: 'Saber mais',
                      section_name: 'Você na frente',
                    })
                  }}
                >
                  Saber mais
                </Button>
              </LinkTo>
            </div>
          </div>
        </div>
      </Section>
    </Parallax>
  )
}

export default Hero
