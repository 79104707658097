import React from 'react'
import Add from '@interco/icons/build-v4/orangeds/LG/add'
import Exit from '@interco/icons/build-v4/orangeds/MD/exit'

import circle from './assets/image/card-image-component-circle.png'
import circle2 from './assets/image/card-image-component-circle-2.png'

import { Card, TextContainer, Button, Container, Title } from './style'

export type CardImageProps = {
  title: string;
  text: string;
  images: {
    md: string;
    lg: string;
    xl: string;
  };
  active: boolean;
  setActive: Function;
  position: number;
  backgroundColor: string;
}

const CardImage = ({ title, text, images, active, setActive, position, backgroundColor }: CardImageProps) => {
  return (
    <Container
      active={active}
    >
      <img src={circle} className='circle circle-left d-none d-lg-block' />
      <img src={circle2} className='circle circle-right d-none d-lg-block' />
      <div>
        <Card
          className='card-benefits mb-0'
          image={images}
          backgroundColor={backgroundColor}
        >
          <TextContainer
            className='text-container'
            active={active}
          >
            <Button
              className='exit d-xl-none'
              onClick={() => setActive(position)}

            >
              <Exit height={16} width={16} color='#161616' />
            </Button>
            <div className='pr-xl-2' dangerouslySetInnerHTML={{ __html: text }} />
          </TextContainer>
          <Button
            onClick={() => setActive(position)}
            className='buttom-add'
          >
            <Add height={16} width={16} color='#161616' />
          </Button>
        </Card>
        <Title>
          <h3 className='fs-20 lh-25 fw-600 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: title }} />
        </Title>
      </div>
    </Container>
  )
}

export default CardImage
