import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const OuwerAdvisory = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const link = 'https://inter.co/pra-voce/relacionamento/inter-black/?utm_source=site&utm_campaign=cr_cross_lpone&utm_medium=crm&utm_content=seja_black'

  return (
    <section className='pt-5 py-md-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-md-center'>
            <h2
              className='fs-24 lh-30 fs-lg-34 fs-lx-40 lh-xl-50 fw-600 text-grayscale--500'
            >
              Com a nossa assessoria, você chega mais longe
            </h2>
            <p
              className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 fw-400 text-grayscale--500'
            >
              Se seu objetivo é sempre investir mais e construir seu patrimônio, o Inter caminha com você durante toda a sua jornada. Seu próximo passo pode ser se tornar{' '}
              <a
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_06',
                    element_action: 'click button',
                    element_name: 'Saber mais',
                    section_name: 'Com a nossa assessoria, você chega mais longe',
                    redirect_url: link,
                  })
                }}
                href={link}
                className='text-orange--extra fw-600'
              >
                Inter Black{' '}
              </a>.
              Converse com seu advisor sobre como chegar lá.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OuwerAdvisory
