import React from 'react'

import Chat from '@interco/icons/build-v4/orangeds/MD/chat'

import OpenVideo from 'src/components/OpenVideo'

import feedbacksImages from '../../../../assets/images/feedback-images-lp-one.png'
import leftQuonte from '../../../../assets/images/left-quote.png'
import charlesAlexandre from '../../../../assets/images/lp-one-charles-alexandre.png'
import terezinhaNeila from '../../../../assets/images/lp-one-terezinha-neila.png'
import thalissonSilva from '../../../../assets/images/lp-one-thalisson-silva.png'
import video from '../../../../assets/images/lp-one-ouwer-clients-video.png'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { CardsRow, Card } from '../../style'

const CardRowMd = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <CardsRow>
      <div>
        <Card>
          <Chat width={40} height={40} color='#161616' />
          <h4 className='fs-16 lh-20 text-black fw-600 my-4'>Depoimentos</h4>
          <img src={feedbacksImages} />
        </Card>

        <Card>
          <img src={leftQuonte} className='quote' />
          <p className='fs-14 lh-17 fw-400 text-grayscale--400'>A facilidade de <span className='high-light--yellow'>conversar com meu advisor é incrível!</span> Eu queria muito investir um dinheiro, mas, não sabia por onde começar. Segui as recomendações do meu advisor e finalmente, criei minha carteira.</p>
          <div className='d-flex align-items-center'>
            <img src={charlesAlexandre} />
            <span
              className='fs-12 lh-15 fw-600 text-black mb-0 ml-3 d-block'
            >
              Charles Alexandre
            </span>
          </div>
        </Card>
      </div>
      <div className='ml-4'>
        <Card>
          <OpenVideo
            link='https://www.youtube.com/embed/kK8WWvu1H4g?&autoplay=1'
            onClick={() => sendDatalayerEvent({
              section: 'dobra_05',
              element_action: 'click video',
              element_name: 'null',
              section_name: 'Inter One por quem mais entende do assunto: os nossos clientes!',
            })}
          >
            <img src={video} className='image-video' />
          </OpenVideo>
        </Card>

        <Card>
          <img src={leftQuonte} className='quote' />
          <p className='fs-14 lh-17 fw-400 text-grayscale--400'>
            Com a possibilidade de ter um advisor, eu trouxe meus investimentos de outros bancos – e me tornei um Inter One. <span className='high-light--blue'>É bom demais ter alguém de fácil acesso pra te auxiliar, de forma leve e fácil.</span>
          </p>
          <div className='d-flex align-items-center'>
            <img src={thalissonSilva} />
            <span
              className='fs-12 lh-15 fw-600 text-black mb-0 ml-3 d-block'
            >
              Thalisson Silva
            </span>
          </div>
        </Card>
      </div>
      <div className='ml-4'>
        <Card className='card-top'>
          <img src={leftQuonte} className='quote' />
          <p className='fs-14 lh-17 fw-400 text-grayscale--400'>
            Antigamente, quando eu era cliente de outros bancos, era uma peleja! Hoje, envio um e-mail para a minha advisor e ela resolve rapidamente. <span className='high-light--orange'>A urgência em que ela trata as coisas, é nota 1000!</span>
          </p>
          <div className='d-flex align-items-center'>
            <img src={terezinhaNeila} />
            <span
              className='fs-12 lh-15 fw-600 text-black mb-0 ml-3 d-block'
            >
              Terezinha Neila
            </span>
          </div>
        </Card>
      </div>
    </CardsRow>
  )
}

export default CardRowMd
