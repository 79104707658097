import styled from 'styled-components'
import { orange, grayscale, white, black } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

type containerProps = {
  theLastItem: boolean;
}

export const Section = styled.section`
  min-height: 584px;
  position: relative;
  background: #ffffff;
  top: -10px;


  @media (min-width: ${breakpoints.md}) {
    top: 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row-reverse;
    min-height: 650px;
  }

  .container {
    @media (min-width: ${breakpoints.xl}) {
      max-width: 1270px;
    }
    @media (min-width: ${breakpoints.xxxl}) {
      max-width: 1440px;
    }
  }
`
export const Container = styled.div`

  .react-multi-carousel-track {
    height: ${(props: containerProps) => props.theLastItem ? '291px' : '180px'};

    @media (min-width: 392px) {
      height: ${(props: containerProps) => props.theLastItem ? '206px' : '180px'};
    }
  }


  @media(min-width: ${breakpoints.md}) {
    .react-multi-carousel-track {
      height: inherit;
    }
  }


  .carrossel {
    padding-bottom: 3rem;

    @media (min-width: 420px) {
      padding-bottom: 2rem;
    }

    @media (min-width: ${breakpoints.md}) {
      padding-bottom: 5rem;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 664px;
    margin: 0 auto;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 492px;
    margin: 0;
  }
  @media (min-width: ${breakpoints.xl}) {
    width: 388px;
    margin: 0;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${breakpoints.md}) {
    min-height: 266px;
    justify-content: end;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: auto;
  }
`
export const Button = styled.button`
  width: 100%; 
  height: 48px;
  border: none;
  border-radius: 8px;
  background: ${orange.extra};
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #fff;

  &:focus {
    outline: none;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 226px;
  }
`
export const ButtonArrow = styled.button`
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  border: none;
  padding: 0;
  position: absolute;
  bottom: 0;

  &:focus {
    outline: none;
  }

  &.right {
    right: 0;
  }

  &.left {
    left: 0;
  }

  @media (min-width: ${breakpoints.md}) {
    bottom: 6;

    &.right {
      right: 100px;
    }

    &.left {
      left: 100px;
    } 
  }

  @media (min-width: ${breakpoints.lg}) {
    bottom: 6;

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    } 
  }
`
export const ButtonDots = styled.button`
  width: 56px;
  height: 6px;
  border-radius: 20px;
  background: ${grayscale[200]};
  border: none;

  & + button {
    margin-left: 8px;
  }

  &.active {
    background: ${grayscale[500]};
  }

  &:focus {
    outline: none;
  }

  @media (min-width: ${breakpoints.md}) {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${white};
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${black};
    border: 2px solid ${black};

    &.active {
      background: ${black};
      color: ${white};
    }
  }

`
export const ButtonGroup = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 248px;

  @media (min-width: ${breakpoints.md}) {
    width: 162px;
  }
`
