import React, { useState } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { widths } from 'src/styles/breakpoints'

import Layout from 'src/layouts/BaseLayout'

import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

import mask from './assets/images/lpInterOneMask.png'

import { Modal } from 'src/components/Modal'
import InterOneContactForm from 'src/components/UI/Forms/InterOneContactForm'

import Hero from './_sections/hero/_index'
import Benefits from './_sections/benefits/_index'
import Carrossel from './_sections/carrossel/_index'
import OuwerClients from './_sections/ouwer-clients/_index'
import KnowMore from './_sections/know-more/_index'
import Faq from './_sections/faq/_index'
import OuwerAdvisory from './_sections/ouwer-advisory/_index'

import PageQuery from './pageContext.json'

import { Wrapper } from './style'

const InterOne = () => {
  const [ openModal, setOpenModal ] = useState(false)
  const [ dobra, setDobra ] = useState('dobra_1')

  const domReady = useDomReady()
  const width = useWidth(300)

  const modalOpen = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <InterOneContactForm closeModal={() => setOpenModal(false)} dobra={dobra} />
    </Modal>
  )

  return (
    <ParallaxProvider>
      <Wrapper>
        <Layout pageContext={PageQuery}>
          {modalOpen}
          <Hero />
          <Benefits setOpenModal={setOpenModal} setDobra={setDobra} />
          {width < widths.md && <img src={mask} className='mask' />}
          <Carrossel setOpenModal={setOpenModal} setDobra={setDobra} />
          <OuwerClients />
          <KnowMore />
          <OuwerAdvisory />
          <Faq />
        </Layout>
      </Wrapper>
    </ParallaxProvider>
  )
}

export default InterOne
