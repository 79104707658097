import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'

export const Section = styled.section`
  background: ${white};

  @media (min-width: ${breakpoints.md}) {
    .container {
      max-width: 1360px;
    }
  }
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow-x: hidden;


  @media (min-width: ${breakpoints.lg}) {
    justify-content: center;
  }
  @media (min-width: ${breakpoints.xl}) {
    height: 645px;
  }
`
export const Button = styled.button`
  width: 340px;
  height: 48px;
  background: ${orange.extra};
  color: ${white};
  border-radius: 8px;
  font-weight: 700;
  border: none;
  margin-top: 0;
  
  &:focus {
    outline: none;
  }
`
