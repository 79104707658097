import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Wrapper = styled.div`
  h1, h2, h3, h4, h5 {
    font-family: 'Sora';
  }

  .mask {
    position: relative;
    z-index: 9;
    min-width: 100%;
  }

  .stories, .video-section {
    bottom: -10px;
    position: relative;

    @media (min-width: ${breakpoints.md}) {
      bottom: 0;
    }
  }
`
