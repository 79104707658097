import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'

type actionProps = {
  active: boolean;
}

type cardImage = {
  image: {
    md: string;
    lg: string;
    xl: string;
  };
  backgroundColor: string;
}

export const Card = styled.div`
  width: 280px;
  height: 280px;
  background-color: ${(props: cardImage) => props.backgroundColor};
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  position: relative;
  transition: all 0.3s ease-in-out;
  background-image: url(${(props: cardImage) => props.image.md});
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
  margin: 0 auto;

  @media (min-width: ${breakpoints.lg}) {
    background-image: url(${(props: cardImage) => props.image.lg});
    height: 366px;
    width: 200px;
    background-size: cover;
    background-position-x: center;
  }
  @media (min-width: ${breakpoints.xl}) {
    background-image: url(${(props: cardImage) => props.image.xl});
    width: 281px;
    height: 362px;
    background-size: 100%;
  }
`
export const TextContainer = styled.div`
  width: 100%;
  /* height: 200px; */
  height: auto;
  background: #484848;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  bottom: ${(props: actionProps) => props.active ? '0' : '-276px'};
  padding: 0 16px;

  &::before {
    content: '';
    display: block;
    width: 463px;
    height: 382px;
    position: absolute;
    border-radius: 50%;
    background: #484848;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
    top: -44px;
  }

  @media (min-width: ${breakpoints.lg}) {
    /* height: 295px; */
    padding: 10px;
    bottom: ${(props: actionProps) => props.active ? '0' : '-390px'};


    &::before {
      width: 368px;
      height: 382px;
    }
  }

  @media(min-width: ${breakpoints.xl}) {
    margin-left: 71px;
    /* height: 248px; */
    bottom: -270px;
    right: -270px;


    &::before {
      top: -71px;
      right: -35px;
      width: 401px;
      height: 393px;
    }
  }
`
export const Button = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${white};
  padding: 0;
  position: absolute;
  bottom: 21px;
  right: 19px;

  &:focus {
    outline: none;
  }

  &.exit {
    top: -38px;

    @media (min-width: ${breakpoints.lg}) {
      bottom: 306px;
    }
  }
`
export const Container = styled.div`
  @media(min-width: ${breakpoints.lg}) {
    min-width: ${(props: actionProps) => props.active ? '293px' : '200px'};
    min-height: ${(props: actionProps) => props.active ? '585px' : '366px'};
  
    display: flex;
    justify-content: center;
    position: relative;
    align-items: end;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    box-sizing: content-box;
    cursor: pointer;
    margin: 0 24px 0 24px;

    &:first-child {
      margin-left: ${(props: actionProps) => props.active ? '65px' : '0'};
    }
    &:last-child {
      margin-right: ${(props: actionProps) => props.active ? '65px' : '0'};
    }

    .circle {
      position: absolute;
      max-width: 100%;
      transition: all 0.3s ease-in-out;

      &-left {
        left: 0;
        bottom: 160px;

        @media (min-width: ${breakpoints.xl}) {
          width: 50%;
          height: 50%;
        }
      }
  
      &-right {
        right: 0;
        top: 0;

        @media (min-width: ${breakpoints.xl}) {
          width: 50%;
          height: 50%;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    min-width: 281px;
    width: 281px;
    height: 444px;

    .buttom-add {
      right: 40px;
    }

    &:hover {
      min-width: 494px;
      min-height: 645px;
      
      &:first-child {
        margin-left: 170px;
      }
      &:last-child {
        margin-right: 170px;
      }
      
      .card-benefits {
        min-width: 359px;
        height: 463px;
      }
      .text-container {
        bottom: 0;
        right: -10px;
        margin-left: 50px;
      }

      .circle {
        &-left {
          left: 0;
          bottom: 80px;

          width: 401px;
          height: 442px;
        }

        &-right {
          right: 0;
          top: 20px;
  
          width: 380px;
          height: 454px;
        }
      }
    }
  }
`
export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 27px;

  h3 {
    width: 280px;
    max-width: 280px;
    min-height: 30px;
  }

  @media(min-width: ${breakpoints.lg}) {
    h3 {
      max-width: 200px;
      min-height: 100px;
    } 
  }
  @media(min-width: ${breakpoints.xl}) {
    h3 {
      width: 100%;
      max-width: 359px; 
      min-height: 50px;
    }
  }
`
