import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'

import bgMd from '../../assets/images/lp-one-ouwer-clients-md.png'
import bgLg from '../../assets/images/lp-one-ouwer-clients-lg.png'

export const Section = styled.section`
  min-height: 582px;
  background-color: #FDFCF9;
  background-repeat: no-repeat;
  background-size: contain;

  @media (min-width: ${breakpoints.md}) {
    background-image: url(${bgMd});
    min-height: 943px;
    background-position-y: bottom;
    background-position-x: right;
    background-size: 504px;
  }
  @media (min-width: ${breakpoints.lg}) {
    background-image: url(${bgLg});
    min-height: 1367px;
    background-position-y: center;
    background-position-x: left;
  }
  @media (min-width: ${breakpoints.xl}) {
    background-image: none;
    overflow-y: hidden;
    height: 678px;
    min-height: 678px;
    position: relative;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const CardsRow = styled.div`
  padding: 0 24px;
  overflow-x: scroll;
  display: flex;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${breakpoints.md}) {
    align-items: flex-start;
    jusistify-content: space-between;
    positions: relative;
    overflow: visible;

    .card-top {
      margin-top: -150px;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    justify-content: flex-end;

    .card-top {
      margin-top: -185px;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    overflow-y: scroll;
    height: 678px;
    margin-top: -335px;

    .column-1 {
      margin-top: 223px;
      margin-bottom: 43px;
    }
    
    .card-top {
      margin-top: 71px;
    }
    .img {
      position: absolute;
      left: 0;
      bottom: -520px;
    }
  }
`
export const Card = styled.div`
  width: 100%;
  padding: 24px 16px;
  border: 1px solid #DEDFE4;
  border-radius: 16px;
  background: ${white};
  position: relative;
  overflow: hidden;

  .quote {
    opacity: 0.2;
    margin-bottom: 16px;
  }

  .high-light {
    &--yellow {
      background-color: #FFE999;
    }
    &--orange {
      background-color: rgba(255, 190, 131, 0.5);
    }
    &--blue {
      background-color: rgba(167, 223, 255, 0.5);
    }
  }

  & + div {
    margin-top: 16px;
  }
  
  .image-video {
    margin-left: -16px;
    max-width: calc(100% + 32px);
    margin-bottom: -24px;
    margin-top: -24px;
    min-height: calc(100% + 24px);
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.md}) {
    width: 210px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 342px;
    padding: 24px;

    .image-video {
      margin-left: -24px;
      max-width: calc(100% + 48px);
      margin-bottom: -24px;
    }

    & + div {
      margin-top: 40px;
    }

  }
`
