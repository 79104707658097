import React from 'react'
import { widths } from 'src/styles/breakpoints'

import useWidth from 'src/hooks/window/useWidth'

import { Section } from './style'

import CardRowSm from './breakpoints/sm/_index'
import CardRowMd from './breakpoints/md/_index'
import CardRowLg from './breakpoints/lg/_index'

const OuwerClients = () => {
  const width = useWidth(300)

  return (
    <Section className='py-3 py-md-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-48 lh-xl-60 fw-600 text-grayscale--500 mb-5'>
              Inter One por quem mais entende do assunto:
              <span className='text-orange--extra'> os nossos clientes!</span>
            </h2>
          </div>
        </div>
        {width < widths.md && <CardRowSm />}
      </div>
      {(width >= widths.md && width < widths.lg) && <CardRowMd />}
      {width >= widths.lg && <CardRowLg />}
    </Section>
 )
}

export default OuwerClients
