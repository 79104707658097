import React, { useState } from 'react'
import Carousel, { ArrowProps, ButtonGroupProps } from 'react-multi-carousel'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import ArrowLeft from '@interco/icons/build-v4/orangeds/MD/arrow-left'

import {
  Section,
  Container,
  Content,
  Button,
  ButtonArrow,
  ButtonDots,
  ButtonGroup,
} from './style'

import ImagesCarrossel from './images-carrossel/_index'

import carrosselData from '../../assets/data/carrossel.json'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1199, min: 992 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 991, min: 768 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
}

type carrosselDataProps = {
  text: string;
  title: string;
  description: string;
}

interface IButtonGroupDotsProps extends ButtonGroupProps {
  position: number;
}

const CustomRightArrow = ({ onClick }: ArrowProps) => {
  return (
    <ButtonArrow onClick={() => onClick && onClick()} className='right'>
      <ArrowRight height={24} width={24} color='#151515' />
    </ButtonArrow>
  )
}
const CustomLefttArrow = ({ onClick }: ArrowProps) => {
  return (
    <ButtonArrow onClick={() => onClick && onClick()} className='left'>
      <ArrowLeft height={24} width={24} color='#151515' />
    </ButtonArrow>
  )
}
const ButtonGroupDots = ({ goToSlide, position }: IButtonGroupDotsProps) => {
  return (
    <ButtonGroup>
      {
        carrosselData.map((item: carrosselDataProps, index: number) => (
          <>
            <ButtonDots
              key={item.title}
              onClick={() => goToSlide && goToSlide(index)}
              className={`${position === index && 'active'} d-md-none`}
            />
            <ButtonDots
              key={item.title}
              onClick={() => goToSlide && goToSlide(index)}
              className={`${position === index && 'active'} d-none d-md-inline`}
            >
              {index}
            </ButtonDots>
          </>
        ))
      }
    </ButtonGroup>
  )
}

type carrosselProps = {
  setOpenModal: Function;
  setDobra: Function;
}

const Carrossel = ({ setOpenModal, setDobra }: carrosselProps) => {
  const [ position, setPosition ] = useState(0)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='pb-3 pb-md-5 pb-lg-0 d-lg-flex align-items-center'>
      <ImagesCarrossel index={position} />

      <div className='container'>
        <Container theLastItem={position >= 3}>
          <Carousel
            responsive={responsive}
            beforeChange={setPosition}
            customLeftArrow={<CustomLefttArrow />}
            customRightArrow={<CustomRightArrow />}
            containerClass='carrossel'
            itemClass='d-md-flex'
            customButtonGroup={<ButtonGroupDots position={position} />}
            customTransition='opacity 1s linear'
            draggable
          >
            {
              carrosselData.map((item: carrosselDataProps) => (
                <Content key={`${item.title}-${item.description}`} className='text-md-center text-lg-left'>
                  <div>
                    <h2
                      className='fs-22 lh-30 fs-lg-32 lh-lg-40 text-grayscale--500 fw-600'
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <div dangerouslySetInnerHTML={{ __html: item.description }} />
                  </div>
                  <div className='d-none d-md-flex justify-content-center justify-content-lg-start'>
                    <Button
                      onClick={() => {
                        setOpenModal(true)
                        setDobra('dobra_4')
                        sendDatalayerEvent({
                          section: 'dobra_04',
                          element_action: 'click button',
                          element_name: 'Quero ser Inter One',
                          section_name: item.text,
                        })
                      }}
                    >
                      Quero ser Inter One
                    </Button>
                  </div>
                </Content>
              ))
            }
          </Carousel>
          <div className='d-flex d-md-none justify-content-center justify-content-lg-start mt-4'>
            <Button
              onClick={() => {
              setOpenModal(true)
              setDobra('dobra_4')
              sendDatalayerEvent({
                section: 'dobra_04',
                element_action: 'click button',
                element_name: 'Quero ser Inter One',
              })
              }}
            >
              Quero ser Inter One
            </Button>
          </div>
        </Container>
      </div>
    </Section>
  )
}

export default Carrossel
