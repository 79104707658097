import styled from 'styled-components'
import { orange, white, grayscale } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

import bgHeroSm from '../../assets/images/circle-bg-hero-inter-one-sm.svg'
import bgHeroMd from '../../assets/images/circle-bg-hero-inter-one-md.svg'
import bgHeroLg from '../../assets/images/circle-bg-hero-inter-one-lg.svg'
import bgHeroXl from '../../assets/images/circle-bg-hero-inter-one-xl.svg'

import bgHeroSmDark from '../../assets/images/circle-bg-hero-inter-one-sm-dark.svg'
import bgHeroMdDark from '../../assets/images/circle-bg-hero-inter-one-md-dark.svg'
import bgHeroLgDark from '../../assets/images/circle-bg-hero-inter-one-lg-dark.svg'
import bgHeroXlDark from '../../assets/images/circle-bg-hero-inter-one-xl-dark.svg'

type scrollProps = {
  scrolled: boolean;
}

export const Section = styled.section`
  border-radius: 8px;
  background-image: url(${(props: scrollProps) => props.scrolled ? bgHeroSmDark : bgHeroSm});
  background-color: ${(props: scrollProps) => props.scrolled ? '#484848' : white};
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 750px;
  background-position-x: center;
  display: flex;
  align-items: center;
  transition: all 1s ease-in-out;

  .text {
    color: ${(props: scrollProps) => props.scrolled ? white : grayscale[500]};
    transition: all 0.5s ease-in-out;
  }

  .row {
    margin-bottom: 175px;
  }

  @media (min-width: ${breakpoints.md}) {
    background-image: url(${(props: scrollProps) => props.scrolled ? bgHeroMdDark : bgHeroMd});
    min-height: 677px;
    background-size: cover;
    background-position-x: center;

    .row {
      margin-bottom: 100px;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    background-image: url(${(props: scrollProps) => props.scrolled ? bgHeroLgDark : bgHeroLg});
    min-height: 1019px;
  }
  @media (min-width: ${breakpoints.xl}) {
    background-image: url(${(props: scrollProps) => props.scrolled ? bgHeroXlDark : bgHeroXl});
    min-height: 1329px;
    background-position-x: center;

    .row {
      margin-bottom: 380px;
    }
  }

  @media (min-width:  ${breakpoints.xxxl}) {
    .row {
      margin-bottom: 287px;
    }
  }
`
export const Button = styled.button`
  width: 100%;
  height: 48px;
  background: ${orange.extra};
  border: none;
  border-radius: 8px;
  font-size: 14px;
  line-height: 17px;
  color: ${white};
  font-weight: bold;
  margin-top: 17px;

  &:focus {
    outline: none;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 226px;
    margin-top: 32px;
  }
`
