import styled from 'styled-components'
import { white, grayscale } from 'src/styles/colors'

export const Section = styled.section`
  .progress-styles_wrapper__qQPyW {
    top: 24px;
    max-width: 312px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
  .ProgressBar-styles_wrapper__oqUCo {
    height: 6px;
    background-color: ${white};
  }
  .ProgressBar-styles_bar__x0O50 {
    height: 6px;
    background-color: ${grayscale[500]}
  }
`
