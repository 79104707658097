import React from 'react'

import Phone from '@interco/icons/build-v4/orangeds/MD/phone'
import Email from '@interco/icons/build-v4/orangeds/MD/email'

import { Section } from './style'

const KnowMore = () => {
  return (
    <Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-32 lh-40 fs-md-40 lh-md-50 text-md-center mb-3 text-white fw-600'>
              <span className='d-md-block d-lg-inline'>Quer saber mais sobre o</span> Inter One?
            </h2>
            <p className='fs-16 lh-20 fs-md-18 lh-md-22 text-md-center text-white fw-400 mb-5'>Entre em contato com o nosso time para tirar dúvidas nos canais oficiais de atendimento.</p>
          </div>
          <div className='col-12'>
            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <h3 className='fs-20 lh-25 title'>Quero ser One</h3>
                <div className='d-flex'>
                  <div className='mr-3'>
                    <Phone height={24} width={24} color='#FFFFFF' />
                  </div>
                  <p className='fs-14 lh-17 fw-400 text-white'>Entre em contato pelo telefone{' '}
                    <span className='d-block d-xl-inline phone'>
                      (31) 3003 - 4070
                    </span>
                  </p>
                </div>
                <div className='d-flex'>
                  <div className='mr-3'>
                    <Email height={24} width={24} color='#FFFFFF' />
                  </div>
                  <p className='fs-14 lh-17 fw-400 text-white'>Envie uma mensagem no chat</p>
                </div>
              </div>
              <div className='col-md-6'>
                <hr className='line mt-0 mb-4' />
                <h3 className='fs-20 lh-25 title'>Já sou cliente One</h3>
                <div className='d-flex'>
                  <div className='mr-3'>
                    <Phone height={24} width={24} color='#FFFFFF' />
                  </div>
                  <p className='fs-14 lh-17 fw-400 text-white'>Entre em contato pelo telefone{' '}
                    <span className='d-block d-xl-inline phone'>
                      (31) 3003 - 4070
                    </span>
                  </p>
                </div>
                <div className='d-flex'>
                  <div className='mr-3'>
                    <Email height={24} width={24} color='#FFFFFF' />
                  </div>
                  <p className='fs-14 lh-17 fw-400 text-white'>Mande uma mensagem para o seu advisor pela Área Exclusiva do
                    Super App
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default KnowMore
