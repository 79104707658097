import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

import imageGrafismoSm from '../../../assets/images/inter-one-image-grafismo-sm.png'
import imageGrafismoMd from '../../../assets/images/inter-one-image-grafismo-md.png'
import imageGrafismoLg from '../../../assets/images/inter-one-image-grafismo-lg.png'
import imageGrafismoXl from '../../../assets/images/inter-one-image-grafismo-xl.png'

import imageUnactiveSlide1Sm from '../../../assets/images/inter-one-image-slider-1-unactive-sm.png'
import imageUnactiveSlide1Md from '../../../assets/images/inter-one-image-slider-1-unactive-md.png'
import imageUnactiveSlide1Lg from '../../../assets/images/inter-one-image-slider-1-unactive-lg.png'
import imageUnactiveSlide1Xl from '../../../assets/images/inter-one-image-slider-1-unactive-xl.png'

import imageUnactiveSlide2Sm from '../../../assets/images/inter-one-image-slider-2-unactive-sm.png'
import imageUnactiveSlide2Md from '../../../assets/images/inter-one-image-slider-2-unactive-md.png'
import imageUnactiveSlide2Lg from '../../../assets/images/inter-one-image-slider-2-unactive-lg.png'
import imageUnactiveSlide2Xl from '../../../assets/images/inter-one-image-slider-2-unactive-xl.png'

import imageUnactiveSlide3Sm from '../../../assets/images/inter-one-image-slider-3-unactive-sm.png'
import imageUnactiveSlide3Md from '../../../assets/images/inter-one-image-slider-3-unactive-md.png'
import imageUnactiveSlide3Lg from '../../../assets/images/inter-one-image-slider-3-unactive-lg.png'
import imageUnactiveSlide3Xl from '../../../assets/images/inter-one-image-slider-3-unactive-xl.png'

import imageActiveSlider1Sm from '../../../assets/images/inter-one-image-slider-1-active-sm.png'
import imageActiveSlider1Md from '../../../assets/images/inter-one-image-slider-1-active-md.png'
import imageActiveSlider1Lg from '../../../assets/images/inter-one-image-slider-1-active-lg.png'
import imageActiveSlider1Xl from '../../../assets/images/inter-one-image-slider-1-active-xl.png'

import imageActiveSlider2Sm from '../../../assets/images/inter-one-image-slider-2-active-sm.png'
import imageActiveSlider2Md from '../../../assets/images/inter-one-image-slider-2-active-md.png'
import imageActiveSlider2Lg from '../../../assets/images/inter-one-image-slider-2-active-lg.png'
import imageActiveSlider2Xl from '../../../assets/images/inter-one-image-slider-2-active-xl.png'

import imageActiveSlider3Sm from '../../../assets/images/inter-one-image-slider-3-active-sm.png'
import imageActiveSlider3Md from '../../../assets/images/inter-one-image-slider-3-active-md.png'
import imageActiveSlider3Lg from '../../../assets/images/inter-one-image-slider-3-active-lg.png'
import imageActiveSlider3Xl from '../../../assets/images/inter-one-image-slider-3-active-xl.png'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  background-image: url(${imageGrafismoSm});
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.md}) {
    height: 373px;
    background-image: url(${imageGrafismoMd});
    margin-left: auto;
    background-position-x: right;
  }
  @media (min-width: ${breakpoints.lg}) {
    background-image: url(${imageGrafismoLg});
    height: 607px;
  }
  @media (min-width: ${breakpoints.xl}) {
    background-image: url(${imageGrafismoXl});
    height: 655px;
    position: absolute;
  }
`
export const Slider1 = styled.div`
  position: absolute;
  background-image: url(${imageUnactiveSlide3Sm});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: left;
  width: 360px;
  height: 100%;
  right: -100px;
  transition: all 1s ease-in-out;

  &.active {
    background-image: url(${imageActiveSlider1Sm});
    right: -15px;
  }

  &.unactive {
    right: 0;
    background-image: url(${imageUnactiveSlide3Sm});
  }

  @media (min-width: ${breakpoints.md}) {
    width: 670px;
    right: -280px;
    background-image: url(${imageUnactiveSlide3Md});

    &.active {
      background-image: url(${imageActiveSlider1Md});
      right: 0;
    }

    &.unactive {
      right: 0;
      background-image: url(${imageUnactiveSlide3Md});
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    width: 565px;
    right: -337px;
    bottom: 0;
    background-image: url(${imageUnactiveSlide3Lg});

    &.active {
      background-image: url(${imageActiveSlider1Lg});
      right: -65px;
    }

    &.unactive {
      right: -65px;
      background-image: url(${imageUnactiveSlide3Lg});
    }
  }
  @media (min-width: ${breakpoints.xl}) {
    width: 869px;
    right: -370px;
    bottom: 0;
    background-image: url(${imageUnactiveSlide3Xl});

    &.active {
      background-image: url(${imageActiveSlider1Xl});
      right: -35px;
    }

    &.unactive {
      right: -35px;
      background-image: url(${imageUnactiveSlide3Xl});
    }
  }
`
export const Slider2 = styled.div`
  position: absolute;
  background-image: url(${imageUnactiveSlide2Sm});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: left;
  width: 360px;
  height: 100%;
  right: -230px;
  transition: all 1s ease-in-out;

  &.active {
    background-image: url(${imageActiveSlider2Sm});
    right: -30px;
  }

  &.unactive {
    background-image: url(${imageUnactiveSlide2Sm});
  }

  @media (min-width: ${breakpoints.md}) {
    width: 670px;
    right: -400px;
    background-image: url(${imageUnactiveSlide2Md});

    &.active {
      background-image: url(${imageActiveSlider2Md});
      right: -36px;
    }

    &.unactive {
      right: -36px;
      background-image: url(${imageUnactiveSlide2Md});
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    width: 565px;
    right: -408px;
    bottom: 0;
    background-image: url(${imageUnactiveSlide2Lg});

    &.active {
      background-image: url(${imageActiveSlider2Lg});
      right: -94px;
    }

    &.unactive {
      right: -94px;
      background-image: url(${imageUnactiveSlide2Lg});
    }
  }
  @media (min-width: ${breakpoints.xl}) {
    width: 869px;
    right: -556px;
    bottom: 0;
    background-image: url(${imageUnactiveSlide2Xl});

    &.active {
      background-image: url(${imageActiveSlider2Xl});
      right: -97px;
    }

    &.unactive {
      right: -97px;
      background-image: url(${imageUnactiveSlide2Xl});
    }
  }
`
export const Slider3 = styled.div`
  position: absolute;
  background-image: url(${imageUnactiveSlide1Sm});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: left;
  width: 360px;
  height: 100%;
  right: -275px;
  transition: all 1s ease-in-out;

  &.active {
    right: -47px;
    background-image: url(${imageActiveSlider3Sm});
  }

  @media (min-width: ${breakpoints.md}) {
    width: 670px;
    right: -485px;
    background-image: url(${imageUnactiveSlide1Md});

    &.active {
      background-image: url(${imageActiveSlider3Md});
      right: -74px;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    width: 565px;
    right: -486px;
    bottom: 0;
    background-image: url(${imageUnactiveSlide1Lg});

    &.active {
      background-image: url(${imageActiveSlider3Lg});
      right: -114px;
    }
  }
  @media (min-width: ${breakpoints.xl}) {
    width: 869px;
    right: -647px;
    bottom: 0;
    background-image: url(${imageUnactiveSlide1Xl});

    &.active {
      background-image: url(${imageActiveSlider3Xl});
      right: -150px;
    }
  }
`
